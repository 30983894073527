body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

 /* Fonts-Family */

   --primary-regular          : Open Sans Regular;
   --primary-italic           : Open Sans Italic;
   --primary-medium           : Open Sans SemiBold;
   --primary-medium-italic    : Open Sans SemiBold Italic;
   --primary-bold             : Open Sans Bold;
   --primary-bold-italic      : Open Sans Bold Italic;
   --primary-extrabold        : Open Sans ExtraBold;
   --primary-extrabold-italic : Open Sans ExtraBold Italic;

   /* App Color Scheme */

   --white       : #FFFFFF;
   --white-two   : #EEEEEE;
   --white-three : #F4F4F4;

   --cool-blue   : #288BFA;
   
   --black       : #373737;
}

::-webkit-scrollbar {
	width  : 6px!important;
	height : 6px!important;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,.2);
}

::-webkit-scrollbar-track {
	background: hsla(0,0%,100%,.1);
}

.black-landing-tag {
	color: rgb(68, 68, 68);
	background: rgb(255, 255, 255);
	border: 1px solid rgb(68, 68, 68);
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 3px;
	padding: 0 0.5rem;
	font-size: 14px;
	line-height: 2em;
	font-weight: 400;
	font-family : var(--primary-regular);
	display : inline-block;
}

.blue-landing-tag {
	color: rgb(39, 139, 250);
	background: rgb(255, 255, 255);
	border: 1px solid rgb(39, 139, 250);
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 3px;
	padding: 0 0.5rem;
	font-size: 14px;
	line-height: 2em;
	font-weight: 400;
	font-family : var(--primary-regular);
	display : inline-block;
}

.swal-button {
	background-color : var(--cool-blue);
}

.swal-button--cancel {
	color: #555;
	background-color: #efefef !important;
}

.swal-button:not([disabled]):hover {
	background-color : rgb(28, 97, 175);
}

.designed-button-contained {
	background-image : linear-gradient(270deg,#4481eb 0,#04befe);
	color            : var(--white) !important
}

.designed-button-outlined {
	color : var (--cool-blue) !important;
}

.embed-container {
	--video--width: 720;
	--video--height: 420;

	position: relative;
	padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
}

.parent-content  {
	height : 100%
}

.actual-content {
	height : calc(100% - 65px);
	overflow : auto;
	padding : 72px;
}

#navlink.active {
	color : var(--cool-blue) !important;	
}

@media (max-width: 960px) {
	.actual-content {
		height : calc(100% - 73px);
		overflow : hidden auto;
		padding : 18px;
	}

	.actual-content.mobile-nav {
		height : calc(100% - 186px);
		overflow : auto;
	}
}

@media (max-width: 600px) and (orientation: landscape) {
	.actual-content {
		height : calc(100% - 49px);
		overflow : auto;
		padding : 18px;
	}

	.actual-content.mobile-nav {
		height : calc(100% - 185px);
		overflow : auto;
	}
}


@media (max-width: 960px) and (orientation: landscape) {
	.actual-content {
		height : calc(100% - 64px);
		overflow : auto;
	}

	.actual-content.mobile-nav {
		height : calc(100% - 184px);
		overflow : auto;
	}
}

.MuiTab-textColorInherit.Mui-selected {
	color         : #e64021;
	border-bottom : 2px solid #e64021;
}

.bunkei-label .MuiFormControlLabel-label {
	color : var(--cool-blue)
}

.MuiAlert-message {
	display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-4 {
	margin : 4px !important;
}

.m-8 {
	margin : 8px !important;
}

.m-12 {
	margin : 12px !important;
}

.m-16 {
	margin : 16px !important;
}

.m-20 {
	margin : 20px !important;

}

.m-24 {
	margin : 24px !important;
}

.p-4 {
	padding : 4px !important;
}

.p-8 {
	padding : 8px !important;
}

.p-12 {
	padding : 12px !important;
}

.p-16 {
	padding : 16px !important;
}

.p-20 {
	padding : 20px !important;

}

.p-24 {
	padding : 24px !important;
}

.p-48 {
	padding : 48px !important;
}

.p-60 {
	padding : 60px !important;
}

.p-72 {
	padding : 60px !important;
}

.mt-4 {
	margin-top : 4px !important;
}

.mr-4 {
	margin-right : 4px !important;
}

.mb-4 {
	margin-bottom : 4px !important;
}

.ml-4 {
	margin-left : 4px !important;
}

.mt-8 {
	margin-top : 8px !important;
}

.mr-8 {
	margin-right : 8px !important;
}

.mb-8 {
	margin-bottom : 8px !important;
}

.ml-8 {
	margin-left : 8px !important;
}

.mt-12 {
	margin-top : 12px !important;
}

.mr-12 {
	margin-right : 12px !important;
}

.mb-12 {
	margin-bottom : 12px !important;
}

.ml-12 {
	margin-left : 12px !important;
}

.mt-16 {
	margin-top : 16px !important;
}

.mr-16 {
	margin-right : 16px !important;
}

.mb-16 {
	margin-bottom : 16px !important;
}

.ml-16 {
	margin-left : 16px !important;
}

.mt-20 {
	margin-top : 20px !important;
}

.mr-20 {
	margin-right : 20px !important;
}

.mb-20 {
	margin-bottom : 20px !important;
}

.ml-20 {
	margin-left : 20px !important;
}
.mt-24 {
	margin-top : 24px !important;
}

.mr-24 {
	margin-right : 24px !important;
}

.mb-24 {
	margin-bottom : 24px !important;
}

.ml-24 {
	margin-left : 24px !important;
}

.pt-4 {
	padding-top : 4px !important;
}

.pr-4 {
	padding-right : 4px !important;
}

.pb-4 {
	padding-bottom : 4px !important;
}

.pl-4 {
	padding-left : 4px !important;
}

.pt-8 {
	padding-top : 8px !important;
}

.pr-8 {
	padding-right : 8px !important;
}

.pb-8 {
	padding-bottom : 8px !important;
}

.pl-8 {
	padding-left : 8px !important;
}

.pt-12 {
	padding-top : 12px !important;
}

.pr-12 {
	padding-right : 12px !important;
}

.pb-12 {
	padding-bottom : 12px !important;
}

.pl-12 {
	padding-left : 12px !important;
}

.pt-16 {
	padding-top : 16px !important;
}

.pr-16 {
	padding-right : 16px !important;
}

.pb-16 {
	padding-bottom : 16px !important;
}

.pl-16 {
	padding-left : 16px !important;
}

.pt-20 {
	padding-top : 20px !important;
}

.pr-20 {
	padding-right : 20px !important;
}

.pb-20 {
	padding-bottom : 20px !important;
}

.pl-20 {
	padding-left : 20px !important;
}

.pt-24 {
	padding-top : 24px !important;
}

.pr-24 {
	padding-right : 24px !important;
}

.pb-24 {
	padding-bottom : 24px !important;
}

.pl-24 {
	padding-left : 24px !important;
}