/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Open Sans Regular';
font-style: normal;
font-weight: normal;
src: local('Open Sans Regular'), url('OpenSans-Regular.woff') format('woff');
}


@font-face {
font-family: 'Open Sans';
font-style: normal;
font-weight: normal;
src: local('Open Sans'), url('OpenSans-Regular.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Italic';
font-style: normal;
font-weight: normal;
src: local('Open Sans Italic'), url('OpenSans-Italic.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Light';
font-style: normal;
font-weight: normal;
src: local('Open Sans Light'), url('OpenSans-Light.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Light Italic';
font-style: normal;
font-weight: normal;
src: local('Open Sans Light Italic'), url('OpenSans-LightItalic.woff') format('woff');
}


@font-face {
font-family: 'Open Sans SemiBold';
font-style: normal;
font-weight: normal;
src: local('Open Sans SemiBold'), url('OpenSans-SemiBold.woff') format('woff');
}


@font-face {
font-family: 'Open Sans SemiBold Italic';
font-style: normal;
font-weight: normal;
src: local('Open Sans SemiBold Italic'), url('OpenSans-SemiBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Bold';
font-style: normal;
font-weight: normal;
src: local('Open Sans Bold'), url('OpenSans-Bold.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Open Sans Bold Italic'), url('OpenSans-BoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Open Sans ExtraBold';
font-style: normal;
font-weight: normal;
src: local('Open Sans ExtraBold'), url('OpenSans-ExtraBold.woff') format('woff');
}


@font-face {
font-family: 'Open Sans ExtraBold Italic';
font-style: normal;
font-weight: normal;
src: local('Open Sans ExtraBold Italic'), url('OpenSans-ExtraBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Light Regular';
font-style: normal;
font-weight: normal;
src: local('Open Sans Light Regular'), url('OpenSans-Light-webfont.woff') format('woff');
}


@font-face {
font-family: 'Open Sans Condensed Bold';
font-style: normal;
font-weight: normal;
src: local('Open Sans Condensed Bold'), url('OpenSans-CondBold.woff') format('woff');
}
