@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
 button {
	 font-family: "Nunito Sans";
}
 ul {
	 list-style-type: none;
	 padding-inline-start: 35px;
}
 svg {
	 width: 100%;
}
 h1 {
	 font-size: 7.5em;
	 margin: 15px 0px;
	 font-weight: bold;
}
 h2 {
	 font-weight: bold;
}

 .btn {
	 width  : 100px;
	 z-index: 1;
	 overflow: hidden;
	 background: transparent;
	 position: relative;
	 padding: 8px 50px;
	 border-radius: 30px !important;
	 cursor: pointer;
	 font-size: 1em;
	 letter-spacing: 2px;
	 transition: 0.2s ease;
	 font-weight: bold;
	 margin: 12px 0px !important;
}
 .btn.green {
	 border: 1px solid #5367a0;
	 color: #5367a0;
}
 .btn.green:before {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 0%;
	 height: 100%;
	 background: #5367a0;
	 z-index: -1;
	 transition: 0.2s ease;
}
 .btn.green:hover {
	 color: #fff;
	 background: #5367a0;
	 transition: 0.2s ease;
}
 .btn.green:hover:before {
	 width: 100%;
}

.space-svg {
	height : 400px;
}

@media (max-width : 600px) {

	.space-svg {
		height : 300px;
	}
}
